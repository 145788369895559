import { Injectable } from "@angular/core";
import { App } from "@capacitor/app";

import {
  RemoteConfigValueType,
  FirebaseRemoteConfigAdapter,
  RemoteConfigValue,
} from "../utils/remote-config-adapter";
import { CountryCodeEnum } from "../models/country.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class RemoteConfigService {
  private readonly fetchCooldown: number = environment.fetchTimeoutMillis;
  private readonly NATIVE_APP_PARAM = "nativeAppVersion";
  private configParamCache: { [key: string]: RemoteConfigValue } = {};
  private lastFetchTimes: { [key: string]: number } = {};

  defaultVersion: string;

  constructor(private firebaseRemoteConfig: FirebaseRemoteConfigAdapter) {
    this.defaultVersion = "0.0.1";
  }

  async init(): Promise<void> {
    await this.firebaseRemoteConfig.init();
  }

  private shouldFetch(param: string): boolean {
    const lastFetchTime = this.lastFetchTimes[param];
    const now = Date.now();
    const cooldownHasElapsed =
      lastFetchTime && now - lastFetchTime >= this.fetchCooldown;

    return !lastFetchTime || cooldownHasElapsed;
  }

  private async fetchIfNeeded(
    param: string,
    paramType: RemoteConfigValueType,
    defaultValue: RemoteConfigValue
  ): Promise<RemoteConfigValue> {
    const shouldFetchParam = this.shouldFetch(param);
    const fallback = this.configParamCache[param] ?? defaultValue;

    if (!shouldFetchParam) {
      return fallback;
    }

    try {
      await this.firebaseRemoteConfig.fetchAndActivate();
      const newValue = await this.firebaseRemoteConfig.getValue(
        param,
        paramType
      );

      this.configParamCache[param] = newValue;
      this.lastFetchTimes[param] = Date.now();

      return newValue;
    } catch (error) {
      console.error("Remote config fetch failed:", error);
      return fallback;
    }
  }

  private async fetchRemoteVersion(): Promise<string> {
    const version = await this.fetchIfNeeded(
      "version",
      "string",
      this.defaultVersion
    );
    return version as string;
  }

  private isVersionNewer(
    remoteVersion: string,
    currentVersion: string
  ): boolean {
    const remoteVersionArr = remoteVersion.split(".");
    const currentVersionArr = currentVersion.split(".");
    for (let i = 0; i < remoteVersionArr.length; i++) {
      const r = Number(remoteVersionArr[i]) || 0;
      const c = Number(currentVersionArr[i]) || 0;
      if (r > c) {
        return true;
      }
      if (r < c) {
        return false;
      }
    }
    return false;
  }

  async isNewVersionAvailable(): Promise<boolean> {
    const currentVersion = await this.getAppVersion();
    const remoteVersion = await this.fetchRemoteVersion();
    return this.isVersionNewer(remoteVersion, currentVersion);
  }

  async showBanksPendingIntegration(country: string): Promise<boolean> {
    const paramName =
      country === CountryCodeEnum.id
        ? "showPendingBanksIDN"
        : "showBanksPendingIntegration";

    const showBanks = await this.fetchIfNeeded(paramName, "boolean", false);
    return showBanks as boolean;
  }

  async getAppVersion(): Promise<string> {
    try {
      const appInfo = await App.getInfo();
      return appInfo.version || this.defaultVersion;
    } catch (error) {
      return this.defaultVersion;
    }
  }

  async fetchNativeAppVersions(): Promise<string> {
    await this.firebaseRemoteConfig.fetchAndActivate();
    const newValue = await this.firebaseRemoteConfig.getValue(
      this.NATIVE_APP_PARAM,
      "string"
    );
    return newValue as string;
  }
}
