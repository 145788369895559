import { Injectable } from "@angular/core";
import { UAParser, IBrowser } from "ua-parser-js";

@Injectable({
  providedIn: "root",
})
export class UserAgentService {
  userAgentParser: UAParser;
  defaultBrowserValue = {
    name: "other",
    version: "",
    major: "",
  };

  constructor() {
    this.userAgentParser = new UAParser(window.navigator.userAgent || "");
  }

  getBrowser(): IBrowser {
    if (this.userAgentParser) {
      return this.userAgentParser.getBrowser();
    }

    return this.defaultBrowserValue;
  }
}
