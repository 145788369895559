import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";
import { environment } from "src/environments/environment";

@Injectable()
export class CompositeErrorHandler implements ErrorHandler {
  private errorHandler: ErrorHandler;

  constructor() {
    if (environment.production) {
      this.errorHandler = new Sentry.SentryErrorHandler();
    } else {
      this.errorHandler = new ErrorHandler();
    }
  }

  handleError(error: any): void {
    // Handle Chunk Load Errors	and Handle HttpErrorResponse (Unknown Errors)
    const chunkFailedMessage = /Loading chunk [^\s]+ failed[\s\S]*$/;
    if (error instanceof HttpErrorResponse && error.status === 0) {
      const errorMessage = error?.message || error.toString();

      if (chunkFailedMessage.test(errorMessage)) {
        (async () => {
          try {
            if ("caches" in window) {
              const cacheNames = await caches.keys();
              await Promise.all(cacheNames.map((name) => caches.delete(name)));
            }
          } catch (cacheError) {
            console.error("Error while clearing caches:", cacheError);
          } finally {
            window.location.reload();
          }
        })();
      }
      return;
    }

    this.errorHandler.handleError(error);
  }
}
