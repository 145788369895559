import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { NavController } from "@ionic/angular";

import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { User } from "../shared/models/user.model";
import { AFTER_ONBOARDING_STATES, RouterStates } from "../shared/consts";
import { StorageService } from "../shared/services/storage.service";
import { PlatformService } from "../shared/services/platform.service";
import { AppPages } from "../shared/models/app.model";

const POST_ONBOARDING_STATES = [
  ...AFTER_ONBOARDING_STATES,
  AppPages.onboardingIntro,
];

@Injectable({
  providedIn: "root",
})
export class AndroidSignupGuard {
  routerStates = RouterStates;
  constructor(
    private storage: StorageService,
    private navController: NavController,
    private platform: PlatformService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const user$ = from(this.storage.get("user"));
    return user$.pipe(
      map((user: User) => {
        const isRedirectPage = state.url.includes("redirect-to-app");
        if (this.platform.isWeb()) {
          return true;
        }

        const doesNotHaveAccess =
          user &&
          user.userState?.page &&
          !POST_ONBOARDING_STATES.includes(user.userState.page) &&
          !isRedirectPage;

        if (this.platform.isAndroid()) {
          // For Android: if it's in pre-onboarding state and not redirect page, return false and navigate to redirect-to-app
          if (doesNotHaveAccess) {
            this.navController.navigateRoot("redirect-to-app");
            return false;
          }
        }

        // For all other cases
        return true;
      })
    );
  }
}
