import { Injectable } from "@angular/core";
import { Storage, StorageConfig } from "@ionic/storage-angular";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class CleverTapStorageService extends StorageService {
  constructor(protected storage: Storage) {
    super(storage);
  }

  /**
   * Initializes the CleverTap storage instance with a custom configuration
   */
  async init(): Promise<void> {
    const config: StorageConfig = {
      name: "clevertap-storage",
      storeName: "clevertap-kv",
    };

    const storage = new Storage(config);
    this._storage = await storage?.create();
  }

  async clear(): Promise<void> {
    await this._storage.clear();
  }
}
