import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import * as moment from "moment";

import {
  format,
  set,
  lastDayOfMonth,
  subMonths,
  addYears,
  isAfter,
  isEqual,
  addDays,
  subDays,
  isSameDay,
  isBefore,
  secondsToMilliseconds,
  isValid,
  differenceInYears,
  addISOWeekYears,
  addMonths,
  isWithinInterval,
  getDate,
  getMonth,
  getYear,
  subYears,
  differenceInDays,
  formatISO,
  startOfMonth,
  parse,
  differenceInMinutes,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { enUS, id, th } from "date-fns/locale";
import {
  DATE_TIME_FORMATS,
  MONTH_YEAR_FORMAT,
  STATEMENT_REQUEST_MONTHS,
} from "../consts";
import { StatementRequestMonth } from "../models/bank.model";
import { LanguageEnum } from "../models/country.model";

@Injectable({
  providedIn: "root",
})
export class DateService {
  constructor(private translateService: TranslateService) {}

  subDateMonths(date: number | Date, amount: number): Date {
    return subMonths(date, amount);
  }

  getStartOfMonth(date: number | Date): Date {
    return startOfMonth(date);
  }

  formatDateToISO(date: number | Date): string {
    return formatISO(date);
  }

  subDateYears(date: number | Date, amount: number) {
    return subYears(date, amount);
  }

  setMomentLocale(language: string): string {
    return moment.locale(language);
  }

  getYear(date: number | Date) {
    return getYear(date);
  }

  getMonth(date: number | Date) {
    return getMonth(date);
  }

  getDate(date: number | Date) {
    return getDate(date);
  }

  isDateWithinInterval(date: number | Date, interval: Interval) {
    return isWithinInterval(date, interval);
  }

  addYears(date: Date | number, amount: number): Date {
    return addISOWeekYears(date, amount);
  }

  addDateMonths(date: Date | number, amount: number) {
    return addMonths(date, amount);
  }

  getDifferenceInYears(
    dateLeft: number | Date,
    dateRight: number | Date
  ): number {
    return differenceInYears(dateLeft, dateRight);
  }

  isDateValid(date: any): boolean {
    return isValid(date);
  }

  isDateFormatValid(date: any, format: string = "DD/MM/YYYY"): boolean {
    return moment(date, format, true).isValid();
  }

  convertSecondsToMilliseconds(seconds: number): number {
    return secondsToMilliseconds(seconds);
  }

  isBefore(date: number | Date, dateToCompare: number | Date): boolean {
    return isBefore(date, dateToCompare);
  }

  isSameDay(dateLeft: number | Date, dateRight: number | Date): boolean {
    return isSameDay(dateLeft, dateRight);
  }

  subDays(date: number | Date, amount: number): Date {
    return subDays(date, amount);
  }

  addDays(date: number | Date, amount: number): Date {
    return addDays(date, amount);
  }

  isDateEqual(dateLeft: Date | number, dateRight: Date | number): boolean {
    return isEqual(dateLeft, dateRight);
  }

  isDateAfter(date: Date | number, dateToCompare: Date | number): boolean {
    return isAfter(date, dateToCompare);
  }

  convertToDate(date: string): string {
    return moment(date, "DD.MM.YYYY HH.mm").toString();
  }

  formatDate(formatting: string, date: string | number | Date): string {
    return format(new Date(date), formatting);
  }

  formatDisplayDate(
    formatting: string,
    date: string | number | Date,
    useLocaleDate = true
  ): string {
    const dateValue = useLocaleDate ? this.getLocaleDate(date) : new Date(date);
    return format(dateValue, formatting, {
      locale: this.mapAppLocale(),
    });
  }

  /**
   * Gets a timezone-adjusted date based on the provided IANA timezone format.
   *
   * @param {Date | number | string} date - The date to be adjusted. T
   * @param {string} timeZone - The IANA timezone name (e.g., 'Asia/Bangkok').
   * This should not be a UTC offset.
   *
   * @returns {Date} The timezone-adjusted date as a Date object.
   */
  getTZAdjustedDate(date: Date | number | string, timeZone: string): Date {
    return utcToZonedTime(date, timeZone);
  }

  getDifferenceInDays(
    dateLeft: number | Date,
    dateRight: number | Date
  ): number {
    return differenceInDays(dateLeft, dateRight);
  }

  formatDisplayDateForPaydaysDecember(
    formatting: string,
    date: string | number | Date
  ): string {
    return format(new Date(date), formatting, {
      locale: this.mapAppLocale(),
    });
  }

  getISOIncludedOffset(date: string | Date | number): string {
    const offset = new Date(date).getTimezoneOffset();
    return new Date(new Date().setMinutes(Math.abs(offset))).toISOString();
  }

  getLastMonth(): string {
    return moment(moment())
      .subtract(1, "month")
      .startOf("month")
      .format("MMMM, YYYY");
  }

  getFirstAndLastMonthDays(date: Date): { startDate: Date; endDate: Date } {
    const startDate = set(date, { date: 1 });
    const endDate = set(date, {
      date: lastDayOfMonth(date).getDate(),
    });

    return { startDate, endDate };
  }

  getStatementRequestMonths(bankId: string): StatementRequestMonth[] {
    let currDate = new Date();

    // get days of a current month
    const { startDate } = this.getFirstAndLastMonthDays(currDate);

    // get the number of previous months
    const numberOfPreviousMonthRequests = STATEMENT_REQUEST_MONTHS;

    const months: StatementRequestMonth[] = [];
    months.push({
      month: this.formatDate(MONTH_YEAR_FORMAT, startDate),
      complete: false,
      endDate: null,
      startDate: null,
    });

    // get previous months depending on the bank
    for (let index = 0; index < numberOfPreviousMonthRequests; index++) {
      const prevMonth = this.subDateMonths(currDate, 1);

      const startDate = set(prevMonth, { date: 1 });

      months.push({
        month: this.formatDate(MONTH_YEAR_FORMAT, startDate),
        complete: false,
        endDate: null,
        startDate: null,
      });

      currDate = prevMonth;
    }

    return months;
  }

  convertStatementDate(statementDate: string): Date {
    const date = statementDate.split("-");
    const dateResult = set(new Date(), {
      year: +date[0],
      month: +date[1] - 1,
      date: 1,
    });

    return dateResult;
  }

  getDefaultStatementStartDate(): Date {
    const numberOfRequestMonths = STATEMENT_REQUEST_MONTHS;
    const currentMonthStartDate = set(new Date(), { date: 1 });

    return this.subDateMonths(currentMonthStartDate, numberOfRequestMonths);
  }

  private mapAppLocale(): Locale {
    switch (this.translateService.currentLang) {
      case LanguageEnum.en:
      case LanguageEnum.ph:
        return enUS;
      case LanguageEnum.id:
        return id;
      default:
        return th;
    }
  }

  private getLocaleDate(stringDate: string | number | Date): Date {
    const date = new Date(stringDate);

    switch (this.translateService.currentLang) {
      case LanguageEnum.en:
      case LanguageEnum.ph:
      case LanguageEnum.id:
        return date;
      default:
        return addYears(date, 543);
    }
  }

  getCurrentLang(): string {
    return this.translateService.currentLang;
  }

  convertDateFormat(inputDate: string): string {
    if (!inputDate) {
      return "";
    }

    const dateParts = inputDate.split("-");
    if (dateParts.length !== 3) {
      return "";
    }

    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];

    return `${day}/${month}/${year}`;
  }

  parseDate(formatting: string, stringDate: string): Date {
    return parse(stringDate, formatting, new Date());
  }

  hasElapsedMinutes(date: string | number | Date, minutes: number): boolean {
    const now = new Date();
    const prevDate = new Date(date);
    return differenceInMinutes(now, prevDate) >= minutes;
  }

  /**
   * Returns the time format string based on the specified or current language.
   *
   * @param lang - An optional parameter specifying the language. If not provided,
   *               the current language setting is used.
   *
   * @returns A string representing the time format.
   */
  getTimeFormat(lang?: LanguageEnum): string {
    const currentLang = lang || this.getCurrentLang();

    switch (currentLang) {
      case LanguageEnum.th:
        return "HH:mm 'น.'";
      case LanguageEnum.en:
        return "HH:mm";
      default:
        return "h:mm a";
    }
  }

  /**
   * Converts various date formats to a timestamp in milliseconds.
   *
   * @param date - The input date to be converted. Can be a string date,
   *               a unix timestamp, or a Date object.
   *
   * @returns Timestamp in milliseconds. If the input is invalid or cannot be parsed, it returns the current timestamp.
   */
  getTimestamp(date: string | number | Date): number {
    if (!date) {
      return Date.now();
    }

    if (date instanceof Date) {
      return date.getTime();
    }

    // Check if the input is a unix timestamp
    if (typeof date === "number") {
      return this.convertSecondsToMilliseconds(date);
    }

    const isoDate = new Date(date);
    if (!isNaN(isoDate.getTime())) {
      return isoDate.getTime();
    }

    // Attempt parsing with common formats
    const parsedDate = this.parseWithFormats(date);

    // Fallback: return the current timestamp for invalid formats
    return parsedDate ? parsedDate : Date.now();
  }

  /**
   * Attempts to parse a date string using predefined formats.
   *
   * @param date - The input date string to be parsed.
   * @returns Parsed timestamp in milliseconds, or null if parsing fails.
   */
  private parseWithFormats(date: string | number): number | null {
    const parsedDate = DATE_TIME_FORMATS.map((format) =>
      parse(date.toString(), format, new Date())
    ).find((result) => !isNaN(result.getTime()));

    return parsedDate ? parsedDate.getTime() : null;
  }
}
