import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { CleverTapStorageService } from "./clevertap-storage.service";

@Injectable({
  providedIn: "root",
})
export class CleverTapService {
  private readonly renderer: Renderer2;
  private ctWebNativeDisplayListener: () => void;

  constructor(
    private readonly rendererFactory: RendererFactory2,
    private readonly cleverTapStorage: CleverTapStorageService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  /**
   * Initializes CleverTap listener
   */
  public init(): void {
    this.listenForWebNativeDisplay();
  }

  /**
   * Listens for the 'CT_web_native_display' event and handles key-value pairs
   */
  private listenForWebNativeDisplay(): void {
    this.ctWebNativeDisplayListener = this.renderer.listen(
      "document",
      "CT_web_native_display",
      (event: CustomEvent) => {
        if (event?.detail) {
          this.handleKeyValuePairs(event.detail.kv);
        }
      }
    );
  }

  /**
   * Handles incoming key-value pairs
   */
  private async handleKeyValuePairs(kv: Record<string, any>): Promise<void> {
    if (!kv?.topic) {
      return;
    }

    await this.cleverTapStorage.set(kv.topic, {
      ...kv,
      expirationTimestamp: this.calculateExpirationTimestamp(kv.runtime),
    });
  }

  /**
   * Calculates the expiration timestamp based on the campaign runtime
   */
  private calculateExpirationTimestamp(runtime: number): number {
    const campaignDuration = runtime || 60; // Default to 60 minutes if not provided
    return Date.now() + campaignDuration * 60 * 1000;
  }

  /**
   * Retrieves key-value pairs for a specific topic from if they exist and are not expired
   */
  async getKeyValuePairs(topic: string): Promise<any> {
    const { value } = await this.cleverTapStorage.get(topic);
    if (!value) {
      return null;
    }

    const kv = JSON.parse(value);

    // Check if the key-value pair has expired
    if (Date.now() > kv.expirationTimestamp) {
      this.cleverTapStorage.remove(topic);
      return null;
    }

    return kv;
  }

  /**
   * Cleans up all event listeners to avoid memory leaks
   */
  cleanup(): void {
    if (this.ctWebNativeDisplayListener) {
      this.ctWebNativeDisplayListener();
    }
  }
}
