import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { NavController } from "@ionic/angular";

import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { User } from "../shared/models/user.model";
import { AFTER_ONBOARDING_STATES, RouterStates } from "../shared/consts";
import { StorageService } from "../shared/services/storage.service";

@Injectable({
  providedIn: "root",
})
export class MainLocalGuard {
  routerStates = RouterStates;
  constructor(
    private storage: StorageService,
    private navController: NavController
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const user$ = from(this.storage.get("user"));
    return user$.pipe(
      map((user: User) => {
        const hasAccess =
          user &&
          user.userState?.page &&
          AFTER_ONBOARDING_STATES.includes(user.userState.page);
        if (!hasAccess) {
          this.navController.navigateRoot("onboarding-intro", {
            queryParams: route.queryParams,
          });
        }

        return hasAccess;
      })
    );
  }
}
