import { Injectable, isDevMode } from "@angular/core";
import { Storage } from "@ionic/storage-angular";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  promoCodeSubject = new BehaviorSubject("");
  protected _storage: Storage;

  constructor(protected storage: Storage) {}

  async init() {
    const storage = await this.storage?.create();
    if (storage) {
      this._storage = storage;
    }
  }

  set(key: string, value: any): Promise<any> {
    return this._storage?.set(key, value);
  }

  get(key: string): Promise<any> {
    return this._storage?.get(key);
  }

  remove(key: string): void {
    this._storage?.remove(key);
  }

  getAll(): Promise<string[]> {
    return this._storage?.keys();
  }

  async removeAll(): Promise<any> {
    // staging only
    let stagingCreds = "";
    if (isDevMode()) {
      stagingCreds = await this.storage.get("stagingCreds");
    }

    this._storage?.clear();

    // staging only
    if (stagingCreds) {
      return this._storage?.set("stagingCreds", stagingCreds);
    }

    return Promise.resolve();
  }
}
